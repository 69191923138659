























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Order, OrderExternalStatus } from '@/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import formatter from '@/helpers'

dayjs.extend(utc)
dayjs.extend(timezone)

@Component({})
export default class Status extends Vue {
  @Prop() order!: Order
  @Prop() email!: string
  @Prop(Boolean)header!: boolean

  public OrderExternalStatus = OrderExternalStatus

  public workTime (): boolean {
    return formatter.workTime()
  }

  public startWorkTime (): string {
    return formatter.startWorkTime()
  }

  public setPriceTo (value: string | undefined): string {
    if (value === undefined) {
      return ''
    }

    return dayjs(value).add(30, 'minute').format('HH:mm')
  }

  public orderReadyTime (value: string | undefined): string {
    if (value === undefined) {
      return ''
    }

    const now = dayjs(value).tz('Europe/Moscow')

    return now.hour(23).minute(30).tz(dayjs.tz.guess()).format('DD.MM.YYYY HH:mm')
  }

  public tzDiffer (): boolean {
    return formatter.tzDiffer()
  }
}
