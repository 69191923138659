





















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Benefit from '@/components/Benefit.vue'

@Component({ components: { Benefit } })
export default class ShopInfo extends Vue {}
