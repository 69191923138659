











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class PaymentResult extends Vue {
  @Prop(String) status!: string
}
