import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  formatDate: function (value: string | undefined): string {
    if (value === null) {
      return ''
    }

    return dayjs(value).format('DD.MM.YYYY HH:mm')
  },
  formatDateDay: function (value: string | undefined): string {
    if (value === null) {
      return ''
    }

    return dayjs(value).format('DD.MM.YYYY')
  },
  tzDiffer (): boolean {
    if (dayjs.tz.guess() !== 'Europe/Moscow') {
      return true
    }

    return false
  },
  startWorkTime (): string {
    const now = dayjs().tz('Europe/Moscow')

    if (now.hour() < 11) {
      return now.hour(11).minute(0).tz(dayjs.tz.guess()).format('HH:mm')
    }
    if (now.hour() > 19) {
      return now.add(1, 'day').hour(11).minute(0).tz(dayjs.tz.guess()).format('HH:mm')
    }

    return ''
  },
  startCallTime (): string {
    const now = dayjs().tz('Europe/Moscow')

    return now.hour(11).minute(0).tz(dayjs.tz.guess()).format('HH:mm')
  },
  endCallTime (): string {
    const now = dayjs().tz('Europe/Moscow')

    return now.hour(20).minute(0).tz(dayjs.tz.guess()).format('HH:mm')
  },
  workTime (): boolean {
    const now = dayjs().tz('Europe/Moscow')

    if (now.hour() >= 11 && now.hour() <= 19) {
      return true
    }

    return false
  },
  minOrderDate (orderType: string): Date {
    let now = dayjs().tz('Europe/Moscow')

    if (now.hour() >= 20) {
      now = now.add(2, 'days')
    } else {
      now = now.add(1, 'days')
    }

    if (orderType === 'Дипломная работа') {
      now = now.add(2, 'days')
    }

    return now.hour(23).minute(30).tz(dayjs.tz.guess()).toDate()
  },
  normalFinishDate (): string {
    let now = dayjs().tz('Europe/Moscow')

    if (now.hour() >= 20) {
      now = now.add(1, 'week').add(1, 'days')
    } else {
      now = now.add(1, 'week')
    }

    return now.hour(23).minute(30).tz(dayjs.tz.guess()).toISOString()
  },
  setPriceTo (): string {
    return dayjs().add(30, 'minute').format('HH:mm')
  }
}
