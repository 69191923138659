























































import Vue from 'vue'
import { Component, Watch, Prop } from 'vue-property-decorator'
import { OrderService, Order, OrderExternalStatus } from '@/api'
import formatter from '@/helpers'
import Status from '@/components/lk/Status.vue'

@Component({ components: { Status } })
export default class OrderView extends Vue {
  public order: Order | null = null
  public OrderExternalStatus = OrderExternalStatus
  @Prop(String) id!: string

  public parsedID = 1

  @Watch('id')
  idChanged () {
    this.parsedID = this.getID(this.id)
    this.getOrder()
  }

  getID (val: string): number {
    const id = Number.parseInt(val, 10)
    if (Number.isNaN(id)) {
      return 1
    }

    return id
  }

  mounted () {
    this.parsedID = this.getID(this.id)
    this.getOrder()
  }

  getOrder () {
    OrderService.getOrder({ id: this.parsedID }).then(value => {
      this.order = value
    })
  }

  public formatDate (val: string | undefined): string {
    return formatter.formatDateDay(val)
  }

  get email (): string {
    return this.$store.getters.email
  }
}
