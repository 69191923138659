













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ShopService, ShopGoods } from '@/api'
import CyrillicToTranslit from 'cyrillic-to-translit-js'
import ShopInfo from '@/components/ShopInfo.vue'

@Component({ components: { ShopInfo } })
export default class Goods extends Vue {
  private cyrillicToTranslit = new CyrillicToTranslit()
  private goods: ShopGoods = []
  private lesson = ''
  @Prop(String) lessonIn!: string

  mounted () {
    if (this.lessonIn !== undefined) {
      this.lesson = this.lessonIn
    }

    ShopService.getShopLessons().then(value => {
      value.items.some(less => {
        const translited = this.cyrillicToTranslit.transform(less.lesson, '-').toLowerCase()

        if (translited !== this.$route.params.lesson) {
          return false
        }

        this.lesson = less.lesson
        return true
      })

      return ShopService.getShopGoods({ lesson: this.lesson })
    }).then(value => {
      this.goods = value.items
    })
  }
}
