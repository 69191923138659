





























import Vue from 'vue'
import { Component, Watch, Prop } from 'vue-property-decorator'
import { OrderService, Order, OrderExternalStatus } from '@/api'

@Component({})
export default class OrderView extends Vue {
  public order: Order | null = null
  public OrderExternalStatus = OrderExternalStatus
  @Prop(String) id!: string

  public parsedID = 1

  @Watch('id')
  idChanged () {
    this.parsedID = this.getID(this.id)
    this.getOrder()
  }

  getID (val: string): number {
    const id = Number.parseInt(val, 10)
    if (Number.isNaN(id)) {
      return 1
    }

    return id
  }

  mounted () {
    this.parsedID = this.getID(this.id)
    this.getOrder()
  }

  getOrder () {
    OrderService.getOrder({ id: this.parsedID }).then(value => {
      this.order = value
    })
  }

  get neededPayment (): number {
    if (!this.order) {
      return 0
    }

    if (this.order.external_status === OrderExternalStatus.TO_PREPAY) {
      return this.order.to_prepay ?? 0
    }

    return this.order.to_pay ?? 0
  }

  get payID (): string {
    if (!this.order) {
      return ''
    }

    let id = this.order.id.toString()

    if (this.order.external_status === OrderExternalStatus.TO_PREPAY) {
      id += '-1'
    } else if (this.order.external_status === OrderExternalStatus.TO_PAY) {
      id += '-2'
    }

    return id
  }

  get userID (): number {
    return this.$store.getters.id
  }
}
