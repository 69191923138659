




























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { UserService, UserToRegisterData, ApiError, UserToLoginData, User } from '@/api'

interface PhoneValue {
  isValid: boolean;
  e164: string;
}

@Component({ components: { VuePhoneNumberInput } })
export default class Register extends Vue {
  public phonetoStore = ''
  public blocked = false
  public req: UserToRegisterData = {
    name: '',
    email: '',
    phone: ''
  }

  public inputErrors = false

  phoneUpdates (e: PhoneValue) {
    if (e.isValid) {
      this.req.phone = e.e164
    }
  }

  register (e: Event) {
    e.preventDefault()
    this.blocked = true
    this.inputErrors = false

    UserService.registerUser({ requestBody: this.req }).then(user => {
      this.doneAuth(user)
    }).catch((err: ApiError) => {
      if (err.status === 403) {
        this.inputErrors = true
      } else if (err.status === 409) {
        this.login()
      } else {
        this.inputErrors = true
      }
    }).finally(() => {
      this.blocked = false
    })
  }

  doneAuth (user: User) {
    this.$cookies.set('token', user.token, { expires: '10y' })

    this.$store.commit('authorize')
    this.$store.commit('setEmail', user.email)
    this.$store.commit('setDiscount', user.discount)
    this.$store.commit('setPhone', user.phone)
    this.$store.commit('setID', user.id)

    this.$router.push('/order/new.html')
  }

  login () {
    this.blocked = true

    const loginReq: UserToLoginData = {
      email: this.req.email,
      phone: this.req.phone
    }

    UserService.loginUser({ requestBody: loginReq }).then(user => {
      this.doneAuth(user)
    }).finally(() => {
      this.blocked = false
    })
  }
}
