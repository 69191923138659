





























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { UserService, UserToLoginData } from '@/api'

interface PhoneValue {
  isValid: boolean;
  e164: string;
}

@Component({ components: { VuePhoneNumberInput } })
export default class HeaderImage extends Vue {
  public blocked = false
  public phonetoStore = ''

  public request: UserToLoginData = {
    email: '',
    phone: ''
  }

  login (e: Event) {
    e.preventDefault()
    this.blocked = true

    UserService.loginUser({ requestBody: this.request }).then(user => {
      this.$cookies.set('token', user.token, { expires: '10y' })

      this.$store.commit('authorize')
      this.$store.commit('setEmail', user.email)
      this.$store.commit('setDiscount', user.discount)
      this.$store.commit('setPhone', user.phone)
      this.$store.commit('setID', user.id)

      this.$router.push('/order/new.html')
    }).finally(() => {
      this.blocked = false
    })
  }

  phoneUpdates (e: PhoneValue) {
    if (e.isValid) {
      this.request.phone = e.e164
    }
  }
}
