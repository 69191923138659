import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies-ts'
import dayjs from 'dayjs'
import Dayjs from 'vue-dayjs'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import '../public/css/autocomplete.css'

Vue.use(VueCookies)

Vue.config.productionTip = false

Vue.use(Dayjs)
Vue.use(Autocomplete)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.filter('formatDate', function (value: string) {
  if (value) {
    return dayjs(value).format('DD.MM.YYYY HH:mm')
  }
})
