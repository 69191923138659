














































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { UserService, OpenAPI } from '@/api'

type Headers = Record<string, string>

@Component
export default class HeaderImage extends Vue {
  public logoId = 1

  get email () {
    return this.$store.getters.email
  }

  get authorized () {
    return this.$store.getters.authorized
  }

  mounted () {
    if (Math.random() > 0.5) {
      this.logoId = 2
    }

    const urlParams = new URLSearchParams(window.location.search)
    const advInitiated = this.$cookies.isKey('adv_initiated')

    if (urlParams.has('yclid') && !advInitiated) {
      this.$cookies.set('adv_initiated', 'direct', { expires: '14d' })
    }

    OpenAPI.HEADERS = this.getToken

    if (this.$cookies.isKey('token')) {
      UserService.getUserSelf().then(user => {
        this.$store.commit('authorize')
        this.$store.commit('setEmail', user.email)
        this.$store.commit('setDiscount', user.discount)
        this.$store.commit('setPhone', user.phone)
        this.$store.commit('setID', user.id)
      })
    }
  }

  logout (e: Event) {
    e.preventDefault()
    this.$cookies.set('token', '', { expires: '1s' })
    this.$store.commit('unauthorize')
  }

  getToken (): Promise<Headers> {
    return new Promise(resolve => {
      const token = this.$cookies.get('token')

      if (token === null || typeof token !== 'string') {
        resolve({})
        return
      }

      resolve({ token })
    })
  }
}
