
















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { ShopService, ShopLessons } from '@/api'
import CyrillicToTranslit from 'cyrillic-to-translit-js'
import { Route, NavigationGuardNext } from 'vue-router'
import ShopInfo from '@/components/ShopInfo.vue'

Component.registerHooks([
  'beforeRouteUpdate'
])

@Component({ components: { ShopInfo } })
export default class Shop extends Vue {
  public letters: Array<string> = []
  private lessons: ShopLessons = []
  public currentLetter = ''
  public cyrillicToTranslit = new CyrillicToTranslit()

  get currentLessons () {
    const current: ShopLessons = []

    this.lessons.forEach((lesson) => {
      if (lesson.lesson.substring(0, 1).toUpperCase() === this.currentLetter) {
        current.push(lesson)
      }
    })

    return current
  }

  mounted () {
    ShopService.getShopLessons().then(value => {
      const allLetters: Array<string> = []

      if (this.$route.params.letter !== undefined) {
        this.currentLetter = this.cyrillicToTranslit.reverse(this.$route.params.letter.toUpperCase())
      } else {
        this.currentLetter = value.items[0].lesson.substring(0, 1).toUpperCase()
      }

      value.items.forEach((lesson) => {
        allLetters.push(lesson.lesson.substring(0, 1).toUpperCase())
      })

      this.letters = [...new Set(allLetters)]

      this.lessons = value.items
    })
  }

  beforeRouteUpdate (to: Route, from: Route, next: NavigationGuardNext) {
    if (to.params.letter !== undefined) {
      this.currentLetter = this.cyrillicToTranslit.reverse(to.params.letter.toUpperCase())
    }

    next()
  }
}
