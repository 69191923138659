








































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { OrderService, Orders, OrderExternalStatus } from '@/api'
import Status from '@/components/lk/Status.vue'

@Component({ components: { Status } })
export default class OrdersHistory extends Vue {
  public orders: Orders = []
  public OrderExternalStatus = OrderExternalStatus
  public loaded = false

  get email (): string {
    return this.$store.getters.email
  }

  get phone (): string {
    return this.$store.getters.phone
  }

  get discount (): number {
    return this.$store.getters.discount
  }

  mounted () {
    OrderService.getOrdersHistory().then((orders) => {
      this.orders = orders
    }).finally(() => {
      this.loaded = true
    })
  }

  showSpamCheck (): boolean {
    const email = this.email
    if (email.endsWith('@gmail.com')) {
      return true
    }

    return false
  }
}
