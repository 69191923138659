

































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Order from '@/components/Order.vue'
import Benefit from '@/components/Benefit.vue'

@Component({ components: { Order, Benefit } })
export default class Kursovaya extends Vue {}
