
















































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ShopService, ShopGood } from '@/api'
import ShopInfo from '@/components/ShopInfo.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import axios from 'axios'
import formatter from '@/helpers'

interface ShopResponseFailed {
  email?: boolean;
  phone?: boolean;
}

interface ShopResponse {
  status: boolean;
  failed: ShopResponseFailed;
  url?: string;
}

interface PhoneValue {
  isValid: boolean;
  e164: string;
}

@Component({ components: { ShopInfo, VuePhoneNumberInput } })
export default class Good extends Vue {
  @Prop(String) theme!: string
  @Prop(String) id!: string

  public good: ShopGood | null = null

  public phone = ''
  public email = ''
  public blocked = false
  public phoneRaw = ''

  public parsedID = 0

  public resp: ShopResponse = {
    status: false,
    failed: {
      phone: false,
      email: false
    }
  }

  get authorized () {
    return this.$store.getters.authorized
  }

  @Watch('id')
  idChanged () {
    this.parsedID = this.getID(this.id)
    this.getGood()
  }

  getID (val: string): number {
    const id = Number.parseInt(val, 10)
    if (Number.isNaN(id)) {
      return 0
    }

    return id
  }

  getGood () {
    ShopService.getShopGood({ id: this.parsedID }).then(value => {
      this.good = value
    })
  }

  mounted () {
    this.parsedID = this.getID(this.id)
    this.getGood()
  }

  phoneUpdates (e: PhoneValue) {
    if (e.isValid) {
      this.phone = e.e164
    }
  }

  doBuy (e: Event) {
    e.preventDefault()
    this.blocked = true

    const key = (this.email + this.phone).length

    const data = new FormData()

    if (!this.authorized) {
      data.append('email', this.email)
      data.append('key', key.toString())
      data.append('phone', this.phone)
    }

    axios.post('/ajax/buy/' + this.id + '.json', data).then(resp => {
      const data = resp.data as ShopResponse

      if (data.status && data.url !== undefined) {
        window.location.href = data.url
      } else {
        this.resp.status = data.status
        this.resp.url = data.url
        this.resp.failed = data.failed
      }
    }).finally(() => {
      this.blocked = false
    })
  }

  public formatDate (val: string | undefined): string {
    return formatter.formatDateDay(val)
  }
}
