





































import Vue from 'vue'
import { Component, Watch, Prop } from 'vue-property-decorator'
import { OrderService, Order, OrderExternalStatus, UserService } from '@/api'
import helpers from '@/helpers'
import Status from '@/components/lk/Status.vue'

@Component({ components: { Status } })
export default class OrderAccept extends Vue {
  public order: Order | null = null
  public OrderExternalStatus = OrderExternalStatus
  @Prop(String) id!: string
  public token = ''

  public parsedID = 1

  @Watch('id')
  idChanged () {
    this.parsedID = this.getID(this.id)
    this.getOrder()
  }

  getID (val: string): number {
    const id = Number.parseInt(val, 10)
    if (Number.isNaN(id)) {
      return 1
    }

    return id
  }

  mounted () {
    this.parsedID = this.getID(this.id)

    if (!this.$cookies.isKey('token')) {
      const urlParams = new URLSearchParams(window.location.search)

      if (urlParams.has('token')) {
        const token = urlParams.get('token')
        this.$cookies.set('token', token, { expires: '10y' })

        if (token !== null) {
          this.token = token
        }

        UserService.getUserSelf().then(user => {
          this.$store.commit('authorize')
          this.$store.commit('setEmail', user.email)
          this.$store.commit('setDiscount', user.discount)
          this.$store.commit('setPhone', user.phone)
          this.$store.commit('setID', user.id)

          this.getOrder()
        })
      }

      return
    }

    const token = this.$cookies.get('token')

    if (token !== null && typeof token === 'string') {
      this.token = token
    }

    this.getOrder()
  }

  getOrder () {
    OrderService.getOrder({ id: this.parsedID }).then(value => {
      this.order = value
    })
  }

  public formatDate (val: string | undefined): string {
    return helpers.formatDateDay(val)
  }

  public cancel () {
    if (this.order) {
      // eslint-disable-next-line
      this.order.external_status = OrderExternalStatus.CANCELLED
    }

    OrderService.orderAccept({ id: this.parsedID, requestBody: { accepted: false, call: false } }).then(() => {
      this.$router.push('/order/current.html')
    })
  }

  public accept () {
    if (this.order) {
      // eslint-disable-next-line
      this.order.external_status = OrderExternalStatus.TO_PREPAY
    }

    OrderService.orderAccept({ id: this.parsedID, requestBody: { accepted: true, call: false } }).then(() => {
      this.$router.push('/order/current.html')
    })
  }

  public call () {
    if (this.order) {
      // eslint-disable-next-line
      this.order.external_status = OrderExternalStatus.TO_CALL
    }

    OrderService.orderAccept({ id: this.parsedID, requestBody: { accepted: true, call: true } }).then(() => {
      this.$router.push('/order/current.html')
    })
  }

  get email (): string {
    return this.$store.getters.email
  }
}
